import CircularProgress from '@mui/material/CircularProgress';
import mobileStyles from './app-mobile.module.css';
import Typography from '@mui/material/Typography';
import desktopStyles from './app.module.css';
import Button from '@mui/material/Button';
import ReactPlayer from 'react-player';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import React from 'react';

import { Trans } from 'react-i18next';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.mobileView = false;
    const { offsetWidth } = document.getElementById('root');
    if (offsetWidth <= 470)
      this.mobileView = true;

    this.state = {
      firstname: '-----',
      lastname: '-----',
      videoTitle: '---- ---- ---- ---- ----',
      videoUrl: '',
      progress: 0,
      moreInfo: false,
    };
  }

  componentDidMount() {
    const url = "https://files.cvplugin.com/online-profile/get.php?id=" + this.props.id;
    const options = { method: 'GET' };
    fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
      this.setState(data.profile);
      this.getVideoChunks(data.profile.video);
    });
  }

  getVideoChunks = (fileId) => {
    const fileUrl = 'https://files.cvplugin.com/file-upload-api/';
    const url = "https://files.cvplugin.com/file-upload-api/get/chunks.php?id=" + fileId;
    
    const options = { method: 'GET' };
    fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
      if (data.file_url) {
        this.setState({videoUrl: data.file_url});
      } else {
        const n = data.chunks.length;
        const chunks = data.chunks.map((chunk, index) => {
          return fetch(fileUrl + chunk.chunk_path)
            .then(r => {
              const progress = Math.round((index+1) * 100 / n);
              if (progress > this.state.progress) {
                this.setState({progress: progress});
              }
              return r.blob()
            })
        })

        Promise.all(chunks)
        .then((array) => {
          const file = new File(array, 'resume.mp4', {"type" : "video/mp4"});
          this.setState({videoUrl: URL.createObjectURL(file)});
        })
      }
    });
  }

  trackEvent = () => {
    ReactGA.event({
      category: "Action",
      action: "Download"
    });
  }

  render() {
    const url = 'https://pdf.cvplugin.com/';
    let styles = desktopStyles;
    let width = '55vh';
    let height = '99vh';
    if (this.mobileView) {
      styles = mobileStyles;
      width = '100%';
      height = '100%';
    }

    const emptyVideoStyle = {
      width: width,
      height: height,
      minHeight: '650px',
      display: 'inline-flex',
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'black'
    };

    const boxSx = {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    return <div className={styles.app}>
      <div className={styles.playerContainer} >
      { (!this.state.videoUrl) ? 
        <div style={emptyVideoStyle}>
          <CircularProgress
            variant="determinate"
            sx={{color: 'white'}}
            value={this.state.progress} />
          <Box sx={boxSx}>
            <Typography
              component="div"
              variant="caption"
              sx={{color: 'white'}}>
              {`${this.state.progress}%`}
            </Typography>
          </Box>
        </div> :
        <ReactPlayer
          url={this.state.videoUrl}
          width={width}
          height={height}
          controls={true}
          className={styles.player} />
      }
      </div>
      <div className={styles.information}>
        <div className={styles.header}>
          <h1 className={styles.name}>{this.state.firstname} {this.state.lastname}</h1>
          <p className={styles.tag}>{this.state.videoTitle}</p>
          <p className={styles.tag}>{/*Dispo: immédiatement*/}</p>
        </div>
        <div className={styles.content}>
          <Button
            download
            target="_blank"
            variant="contained"
            onClick={this.trackEvent}
            href={url + this.state.path}
            disabled={(this.state.path) ? false : true}>
            <b><Trans>downloadCV</Trans></b>
          </Button>
          <p className={styles.moreInfo}>
            <a target="_blank" rel="noreferrer" href="https://mycvqr.com/">
              <Trans>learnMore</Trans>
            </a>
          </p>
        </div>
        { (true || !this.state.moreInfo) ? null : 
          <div className={styles.content}>
            <Button className={styles.download} variant="contained"><Trans>downloadCV</Trans></Button>
          </div>
        }
      </div>
    </div>;
  }
}

export default App;
