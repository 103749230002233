import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import NotFound from './NotFound';
import './index.css';

import i18next from './i18n';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-0XPR4XEC1H";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview", window.location.pathname + window.location.search);

const params = new URLSearchParams(window.location.search);
const id = params.get('id');
const lang = params.get('lang') || 'en';
i18next.changeLanguage(lang);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>{ (!id) ? <NotFound /> : <App id={id} /> }</>
);
