import './NotFound.css';


function NotFound() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/MyCVQR.png" className="App-logo" alt="logo" />
        <p>
          Nothing to view here
        </p>
        <a
          className="App-link"
          href="https://mycvqr.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to the homepage
        </a>
      </header>
    </div>
  );
}

export default NotFound;