import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './translations/en.js';
import { fr } from './translations/fr.js';
import { es } from './translations/es.js';
import { pt } from './translations/pt.js';

// Language code used: iso BCP 47
// https://www.techonthenet.com/js/language_tags.php

i18n
  // detect user language
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: ['en', 'fr', 'es', 'pt'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr
      },
      es: {
        translation: es
      },
      pt: {
        translation: pt
      }
    }
  });

export const fallbackLngLabel = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  pt: 'Português'
};

export default i18n;